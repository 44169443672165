import React, { useContext, useRef } from 'react'
import { useEffect, useState } from 'react'
import { Select, Modal, Form, Button, Typography, Table, Popconfirm, InputNumber } from 'antd'
import { observer } from 'mobx-react-lite'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../hooks'
import styled from 'styled-components'
import { PlusOutlined } from '@ant-design/icons'
import type { FormInstance } from 'antd/es/form'
import SelectProductModal from './SelectProductModal'
import { formatCurrency } from 'src/helpers/functions'
import { getPage } from 'src/helpers/constants'

const IMPORT_TO_MAIN_STORE = 0
const EXPORT_PRODUCT_FROM_MAIN_TO_FULLFIMENT = 1
const formItemLayout = {
   labelCol: {
      xs: { span: 24 },
      sm: { span: 4 }
   },
   wrapperCol: {
      xs: { span: 24 },
      sm: { span: 10 }
   }
}
const EditableContext = React.createContext<FormInstance<any> | null>(null)
interface Item {
   key: string
   sku: any
   name: string
   attribute: string
   qty: any
   price?: any
   id: any
   inventory?: string
}

interface EditableRowProps {
   index: number
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
   const [form] = Form.useForm()
   return (
      <Form form={form} component={false}>
         <EditableContext.Provider value={form}>
            <tr {...props} />
         </EditableContext.Provider>
      </Form>
   )
}

interface EditableCellProps {
   title: React.ReactNode
   editable: boolean
   children: React.ReactNode
   dataIndex: keyof Item
   record: Item
   handleSave: (record: Item) => void
}

const EditableCell: React.FC<EditableCellProps> = ({
   title,
   editable,
   children,
   dataIndex,
   record,
   handleSave,
   ...restProps
}) => {
   const [editing, setEditing] = useState(false)
   const inputRef = useRef<any>(null)
   const form = useContext(EditableContext)!

   const WarehouseStore = useStore('WarehouseStore')
   useEffect(() => {
      if (editing) {
         inputRef.current!.focus()
      }
   }, [editing])

   const toggleEdit = () => {
      setEditing(!editing)
      form.setFieldsValue({ [dataIndex]: record[dataIndex] })
   }

   const save = async () => {
      try {
         const values = await form.validateFields()

         toggleEdit()
         handleSave({ ...record, ...values })
      } catch (errInfo) {
         console.log('Save failed:', errInfo)
         WarehouseStore.setInvalid(true)
      }
   }

   let childNode = children

   if (editable) {
      if (editing && dataIndex === 'qty') {
         childNode = (
            <Form.Item
               style={{ margin: 0 }}
               name={dataIndex}
               rules={[
                  {
                     required: true,
                     message: `${title} is required.`
                  }
               ]}
            >
               <InputNumber
                  ref={inputRef}
                  onPressEnter={save}
                  onBlur={save}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  min={1}
                  max={record.inventory ? parseInt(record.inventory) : 10000}
                  step={1}
               />
            </Form.Item>
         )
      } else if ((childNode = editing && dataIndex === 'price')) {
         childNode = (
            <Form.Item
               style={{ margin: 0 }}
               name={dataIndex}
               rules={[
                  {
                     required: true,
                     message: `${title} is required.`
                  }
               ]}
            >
               <InputNumber
                  ref={inputRef}
                  onPressEnter={save}
                  onBlur={save}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  min={1}
                  max={1000000000}
                  step={1}
               />
            </Form.Item>
         )
      } else {
         childNode = (
            <div className="editable-cell-value-wrap" style={{ paddingRight: 24, height: '26px' }} onClick={toggleEdit}>
               {children}
            </div>
         )
      }
   }

   return <td {...restProps}>{childNode}</td>
}

type EditableTableProps = Parameters<typeof Table>[0]

interface DataType {
   key: React.Key
   sku: any
   name: string
   attribute: string
   qty: any
   price?: any
   id: any
   inventory?: string
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>

export const ModalDeliveryWrapper = styled.div`
   .ant-table::-webkit-scrollbar {
      width: 8px;
      /* width of the entire scrollbar */
   }

   // .ant-table-content::-webkit-scrollbar-track {
   //    background: orange;
   //    /* color of the tracking area */
   // }

   // .ant-table-content::-webkit-scrollbar-thumb {
   //    background-color: blue;
   //    /* color of the scroll thumb */
   //    border-radius: 20px;
   //    /* roundness of the scroll thumb */
   //    border: 3px solid orange;
   //    /* creates padding around scroll thumb */
   // }
   .editable-cell {
      position: relative;
   }

   .editable-cell-value-wrap {
      padding: 5px 12px;
      cursor: pointer;
   }

   .editable-row:hover .editable-cell-value-wrap {
      padding: 4px 11px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
   }

   [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
      border: 1px solid #434343;
   }
`
const ProductDeliveryScreen = observer((props: any) => {
   const { t } = useTranslation()

   const { item, visible, onOk, onCancel, exportModal, fulfimentId } = props
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const UserStore = useStore('UserStore')
   const AuthStore = useStore('AuthStore')
   const WarehouseStore = useStore('WarehouseStore')
   const FundStore = useStore('FundStore')
   const { productsSelected } = WarehouseStore
   const { rows: listWarehouse } = WarehouseStore.warehouseList
   const { rows: listSupplier } = UserStore.listSupplier
   const role: any = AuthStore.data_role
   const [form] = Form.useForm()
   const { Title, Text } = Typography
   const [visibleModal, setVisibleModal] = useState(false)


   useEffect(() => {
      fetchData()
   }, [])

   const fetchData = async () => {
    try {
        const res =  await FundStore.getProductList(item?.id, { offset: 0, limit: 1000 })
        WarehouseStore.setProductsSelected(formatListProduct(res))
      
        if (item.type !== IMPORT_TO_MAIN_STORE) {
            await WarehouseStore.getProductWithStoreMain({ offset: 0, limit: 1000 })
            WarehouseStore.getWarehouseList({ offset: 0, limit: 1000 })
         } else {
            await WarehouseStore.getListProducts({ offset: 0, limit: 1000 })
            UserStore.getSupplier()
         }
    } catch (error) {
        console.error(error)
    }
      
   }

   const formatListProduct = (list: any) => {
        return list?.map((item: any) => {
            return {
                id : item.productId,
                name: item.product?.name,
                qty: item.qty,
                price: item.baseCost,
            }
        })
   }
   const handleDelete = (id: number) => {
      const newData = productsSelected.filter((item: any) => item.id !== id)
      console.log(newData)

      WarehouseStore.setProductsSelected(newData)
   }
   const columnsImport: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id',
         width: 50
      },
      {
         title: 'Tên sản phẩm',
         dataIndex: 'name',
         key: 'name',
         width: 150
      },
      {
         title: 'Số lượng',
         dataIndex: 'qty',
         key: 'qty',
         editable: true,
         width: 100,
         render: (_, record: any) => {
            return formatCurrency(record.qty, true)
         }
      },
      {
         title: 'Giá',
         dataIndex: 'price',
         key: 'price',
         editable: true,
         width: 100,
         render: (_, record: any) => {
            return formatCurrency(record.price, true) + 'đ'
         }
      },
      {
         title: 'Action',
         dataIndex: 'action',
         fixed: 'right',
         width: 50,
         render: (_, record: any) =>
            productsSelected.length >= 1 ? (
               <Popconfirm title="Bạn có muốn xóa sản phẩm này không?" onConfirm={() => handleDelete(record.id)}>
                  <a>Xóa</a>
               </Popconfirm>
            ) : null
      }
   ]

   const columnsExport: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id',
         width: 50
      },
      {
         title: 'Tên sản phẩm',
         dataIndex: 'name',
         key: 'name',
         width: 150
      },
      {
         title: 'Số lượng',
         dataIndex: 'qty',
         key: 'qty',
         editable: true,
         width: 100
      },
      {
         title: 'Action',
         dataIndex: 'action',
         fixed: 'right',
         width: 50,
         render: (_, record: any) =>
            productsSelected.length >= 1 ? (
               <Popconfirm title="Bạn có muốn xóa sản phẩm này không?" onConfirm={() => handleDelete(record.id)}>
                  <a>Xóa</a>
               </Popconfirm>
            ) : null
      }
   ]
   const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] =  item.type !== IMPORT_TO_MAIN_STORE
      ? [...columnsExport]
      : [...columnsImport]

   const handleSave = (row: DataType) => {
      const newData = productsSelected.map((item: any) => {
         return item.id === row.id
            ? {
                 ...item,
                 qty: row.qty,
                 price: row.price
              }
            : item
      })

      WarehouseStore.setProductsSelected(newData)
   }

   const components = {
      body: {
         row: EditableRow,
         cell: EditableCell
      }
   }

   const columns = defaultColumns.map((col) => {
      if (!col.editable) {
         return col
      }
      return {
         ...col,
         onCell: (record: DataType) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave
         })
      }
   })

   const formatOption = (list: any) => {
      return list?.map((item: any) => {
         return {
            label: item.name,
            value: item.id
         }
      })
   }

   const onFinish = async (values: any) => {
      try {
         const formData = {
            id: item.id,
            listProduct: [
               ...productsSelected.map((it: any) =>
               {
                if(item.type !== IMPORT_TO_MAIN_STORE) {
                    return {
                         id: it.id, qty: it.qty 
                    }
                }
               return { id: it.id, qty: it.qty, entryPrice: it.price }
               }

               )
            ]
         }
         const res = await WarehouseStore.updateProductWithPromissory(formData)
         if (res?.code === 200) {
            WarehouseStore.setProductsSelected([])
            onOk()
         }
      } catch (error) {
         console.log(error)
      } finally {
      }
   }

   const renderContent = () => {
      return (
         <ModalDeliveryWrapper>
            <Form {...formItemLayout} form={form} name="CreatorDetail" scrollToFirstError onFinish={onFinish}>
               <div style={{ marginBottom: '10px' }}>
                  <Text style={{ marginRight: '5px' }}>Danh sách sản phẩm</Text>
                  <Button
                     type="primary"
                     size="small"
                     shape="circle"
                     icon={<PlusOutlined />}
                     onClick={() => setVisibleModal(true)}
                  />
               </div>
               <Table
                  style={{ marginBottom: '20px' }}
                  pagination={false}
                  bordered
                  components={components}
                  dataSource={productsSelected}
                  rowClassName={() => 'editable-row'}
                  columns={columns as ColumnTypes}
                  scroll={{ x: 1000, y: 240 }}
               />
               {
                !fulfimentId &&    <Form.Item
                labelAlign="left"
                name="selector"
                label={item.type === IMPORT_TO_MAIN_STORE ? 'Nhà cung cấp' : 'Kho vận chuyển'  }
                rules={[
                   {
                      required: true,
                   }
                ]}
                
                initialValue={item.type === IMPORT_TO_MAIN_STORE ? item.supplierId : item.fromStoreId}
             >
                <Select
                   showSearch
                   placeholder="Search to Select"
                   optionFilterProp="children"
                   filterOption={(input, option) => ((option?.label as string) ?? '').includes(input)}
                   filterSort={(optionA, optionB) =>
                      ((optionA?.label as string) ?? '')
                         .toLowerCase()
                         .localeCompare(((optionB?.label as string) ?? '').toLowerCase())
                   }
                   options={formatOption(item.type === IMPORT_TO_MAIN_STORE ? listSupplier : listWarehouse  )}
                   disabled
                />
             </Form.Item>
               }
               
               
            </Form>

            {visibleModal && (
               <SelectProductModal
                  exportProduct={exportModal}
                  visible={visibleModal}
                  onOk={() => setVisibleModal(false)}
                  onCancel={() => setVisibleModal(false)}
               />
            )}
         </ModalDeliveryWrapper>
      )
   }

   return  (
      <Modal
         width={'90%'}
         style={{ maxWidth: '1200px' }}
         visible={visible}
         centered
         title={ item.type === IMPORT_TO_MAIN_STORE || item.type === EXPORT_PRODUCT_FROM_MAIN_TO_FULLFIMENT  ? 'Nhập hàng' : 'Xuất hàng'}
         okText={t(GLOBAL_CLIENT.save)}
         cancelText={t(GLOBAL_CLIENT.close)}
         onCancel={onCancel}
         onOk={form.submit}
         footer={[
            <Button key="back" onClick={onCancel}>
               {t(GLOBAL_CLIENT.close)}
            </Button>,
            <Button key="submit" type="primary" onClick={form.submit} disabled={productsSelected.length === 0}>
               {t(GLOBAL_CLIENT.save)}
            </Button>
         ]}
      >
         {renderContent()}
      </Modal>
   ) 
})

export default ProductDeliveryScreen
