import { observable, action } from 'mobx'
import { responseDefault } from './store.constants'
import { isEmpty } from 'lodash'
import { Request2 } from 'src/common/Request2'
import api from 'src/common/api'
import { PAGE_SIZE, typeFund } from 'src/helpers/constants'
import { warehouseListFake, priceFundFake } from './fakeData'
export const listFundInit = {
   results: [],
   limit: 50,
   page: 1,
   totalPages: 1,
   totalResults: 0,
   collect: 0,
   pay: 0,
   totalAfter: 0
}
export class WarehouseStore {
   @observable warehouseList: any = { ...responseDefault }
   @observable priceWarehouse: any = {
      before: 0,
      collect: 0,
      spend: 0,
      after: 0
   }

   @observable listTypesReceipts: any = {
      results: [{}],
      limit: 50,
      page: 1,
      totalPages: 1,
      totalResults: 0
   }

   @observable listTypesReceiptsFilter: any = {
      results: [],
      limit: 1000,
      page: 1,
      totalPages: 1,
      totalResults: 0
   }

   @observable listTypesReceiptsFilterCreateDetail: any = {
      results: [],
      limit: 1000,
      page: 1,
      totalPages: 1,
      totalResults: 0
   }

   @observable listGroupUser: any = {
      staff: 'staff',
      customer: 'customer',
      partner: 'partner',
      ncc: 'ncc',
      other: 'other'
   }

   @observable listProducts: any = []
   @observable listExportProducts: any = []
   @observable inValid: boolean = false
   @observable productsSelected: any = []
   @action
   async setInvalid(val: boolean) {
      this.inValid = val
   }
   @action
   async getWarehouseList(params: any = {}) {
      // this.warehouseList=warehouseListFake
      // return;
      const result = await Request2.getWithToken(api.stores.getList, { ...params, ...{ limit: PAGE_SIZE } })
      if (!isEmpty(result)) {
         this.warehouseList = result
      }
   }

   @action
   async createFund(params: any = {}) {
      // console.log('params: ', params, 'api.funds.create: ', api.funds.create);

      const result = await Request2.postWithToken(params, api.funds.create)
      // console.log('result: ', result);
      if (params?.type === typeFund.WITHDRAW || params?.type === typeFund.RECHARGE) {
         return result
      }
      return result?.data
   }

   @action
   async approveFund(id: string | number, params: any = {}, history?: any) {
      // console.log('params: ', params, 'api.funds.create: ', api.funds.approve);

      const result = await Request2.postWithToken(params, api.funds.approve + '/' + id)
      // console.log('result: ', result);
      return result?.data
   }

   @action
   async getTotalPrice(from: string, to: string, storeId?: string, productId?: string) {
      // this.priceWarehouse = priceFundFake
      // // return
      const result = await Request2.getWithToken(api.stores.calculateInventory, { from, to, storeId, productId })
      if (!isEmpty(result)) {
         this.priceWarehouse = result
      }
   }
   @action
   async resetStoreDebts() {
      this.warehouseList = { ...listFundInit }
   }

   @action
   async getListProducts(params: any = {}) {
      const result = await Request2.getWithToken(api.products.getList, { ...params })
      if (!isEmpty(result)) {
         this.listProducts = result.rows
         
            
      }
   }

   @action
   setProductsSelected(list: any) {
      if (list.length == 0) {
         this.productsSelected = []
         return
      }
      this.productsSelected = list
   }

   @action
   async importMainStore(params: any) {
      const result = await Request2.postWithToken(params, api.stores.importMainStore)
      return result
   }

   @action
   async exportToFulfiment(params: any) {
      const result = await Request2.postWithToken(params, api.stores.exportToFulfiment)
      return result
   }

   @action
   async getProductWithStoreMain(params: any = {}) {
      const result = await Request2.getWithToken(api.stores.productWithStoreMain, { ...params })
      if (!isEmpty(result)) {
         this.listProducts = result
         this.listExportProducts = result
            .filter((item: any) => item.inventory && item.inventory !== '0')
            .map((item: any) => {
               return {
                  ...item,
                  qty: parseInt(item.inventory)
               }
            })
      }
   }

   @action
   async updateProductWithPromissory(params: any) {
      const result = await Request2.postWithToken(params, api.stores.updateProductWithPromissory)
      return result
   }
}
